body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.suggestion-textbox ul {
    border: 1px solid #ccc;
    border-radius:5px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;
}

.suggestion-textbox li {
    padding: 8px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

    .suggestion-textbox li:hover {
        background-color: #eee;
    }

/* Custom Radio */

.custom-radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 35px;
}

.radio-mark {
  position: relative;
  background-color: #ffffff;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
}

.radio-mark.red {
  border-color: #EA526F;
}

.radio-mark.blue {
  border-color: #25CED1;
}

.red {
  color: #EA526F !important;
}

.blue {
  color: #25CED1 !important;
}

.orange {
  color: #FF8A5B !important;
}

.radio-mark.gray {
  border-color: #BFBFBF;
}

.radio-mark.xs {
  height: 20px;
  width: 20px;
}

.radio-mark.sm {
  height: 25px;
  width: 25px;
}

.radio-mark.md {
  height: 30px;
  width: 30px;
}

.radio-mark.lg {
  height: 35px;
  width: 35px;
}

.custom-radio input[type="radio"]:checked + .radio-mark.red {
  background-color: #EA526F; 
}

.custom-radio input[type="radio"]:checked + .radio-mark.blue {
  background-color: #25CED1;
}

.custom-radio input[type="radio"]:checked + .radio-mark.gray {
  background-color: #BFBFBF; 
}

.radio-mark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input[type="radio"]:checked + .radio-mark:after {
  display: block;
}

p {
  letter-spacing: 0.4px;
}

.status-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
}

.status-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status-box {
  width: 40px;
  height: 20px;
  border-radius: 5px;
}

.good {
  background-color: #25CED1;
}

.average {
  background-color: #FF8A5B;
}

.bad {
  background-color: #EA526F;
}

.status-text {
  font-size: 18px;
  color: #b1b1b1;
}